import "../styles/Section8.scss"
// import { useMemo } from "react";
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import React from 'react'

export default function Section8() {

    // const {isLoaded} = useLoadScript({
    //     googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    // });
    
    // if (!isLoaded) return <div>Loading...</div>;

    
      
    return (
        <div>
            <div className='section8'>
                <div className="container">
                    <div className="text-center">
                        <div className="">
                            <h1 className='h-3'>Manzil</h1>
                            <br />
                            <p className='p-8'><img className='img3' src="/image/phone2.webp" alt="not found" /> +998 55 500 34 34</p>
                            <p className='p-8'><img className='img3' src="/image/loca2.png" alt="not found" /> Amir Temur 86 | Yunusobod Tumani</p>
                            <p className='p-8'>Minor Metrosi Kazakhstan binosi 1-qavati</p>
                            <p className='p-8'><img className='img3' src="/image/email2.png" alt="not found" /><a href="https://t.me/grandeduadmin">grandeduadmin</a></p>
                            <br />
                        </div>
                        <div className="">
                        {/* <Map /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


// function Map() {
//     const center = useMemo(() => ({ lat: 41.328814, lng: 69.283219}), []);

//     return (
//         <GoogleMap
//          zoom={15} 
//          center={center} 
//         mapContainerClassName="map-container">
//             <Marker position={center} />
//         </GoogleMap>
//     );
// }
