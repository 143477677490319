
import React from 'react'

export default function Footer() {
  return (
    <div>
        <h1 className='text-center py-5'>404 Page not Found</h1>
    </div>
  )
}
