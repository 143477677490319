
import "../styles/Section5.scss"
import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import { Swiper, SwiperSlide } from 'swiper/react';

export default function Section5() {
  return (
    <div>
      <div className="section5">
        <div className="container text-center">
          <h1>Sizning Ustozlaringiz</h1>
          <p className='p-2'>Professional ustozlar jamoasi</p>


          <Swiper 
              breakpoints={{
                350: {
                  width: 350,
                  slidesPerView: 1,
                },
                768: {
                  width: 768,
                  slidesPerView: 1,
                },
                1100: {
                  width: 1100,
                  slidesPerView: 2,
                },
                1400: {
                  width: 1400,
                  slidesPerView: 3,
                },
              }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={-150}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
          >
            <SwiperSlide>
              <div className="cards card5 p-2">
                
                <a data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample">
                <img className="w-50" src="./images2/1.JPG"
                  alt="" />
                </a>
                <div class="collapse" id="collapseExample1">
                  <div class="card card-body">
                    Some placeholder content for the collapse component.
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cards p-2">
              <a data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample">
                <img className="w-50" src="./images2/2.JPG"
                  alt="" />
                </a>
                <div class="collapse" id="collapseExample2">
                  <div class="card card-body">
                    Some placeholder content for the collapse component. 
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="cards p-2">
              <a data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample">
                <img className="w-50" src="./images2/3.JPG"
                  alt="" />
                </a>
                <div class="collapse" id="collapseExample3">
                  <div class="card card-body">
                    Some placeholder content for the collapse component.
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="cards p-2">
              <a data-bs-toggle="collapse" href="#collapseExample4" role="button" aria-expanded="false" aria-controls="collapseExample">
                <img className="w-50" src="./images2/4.JPG"
                  alt="" />
                </a>
                <div class="collapse" id="collapseExample4">
                  <div class="card card-body">
                    Some placeholder content for the collapse component. 
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="cards card4 p-2">
              <a data-bs-toggle="collapse" href="#collapseExample5" role="button" aria-expanded="false" aria-controls="collapseExample">
                <img className="w-50" src="./images2/5.JPG"
                  alt="" />
                </a>
                <div class="collapse" id="collapseExample5">
                  <div class="card card-body">
                    Some placeholder content for the collapse component. 
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}


